import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/match/page/matchLive.module.scss';
import * as itemStyle from 'styles/components/news/item.module.scss';
import { MATCH_STATUS } from 'data/constants';
import getAllMatchEventsById from 'data/queries/getAllMatchEventsById';
import getEventById from 'data/queries/getEventById';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import Modal from 'components/utils/Modal';

import client from '../../../../sanity-client-config';

const EVENTS_PER_PAGE = 5;
const TIME_START_SUBSCRIPTION_BEFORE = 90;
const TRANSITION_TYPE = {
    update: 'update',
    appear: 'appear',
    disappear: 'disappear',
};

const MatchLive = ({ match: { match }, openImageGallery }) => {
    const { lang } = useContext(LangContext);
    const [nextEvents, setNextEvents] = useState(EVENTS_PER_PAGE);
    const [isSubscribeToEvents, setIsSubscribeToEvents] = useState(false);
    const [videoPlaying, setVideoPlaying] = useState(false);
    const [videoPlayingUrl, setVideoPlayingIUrl] = useState(null);
    const [matchEvents, setMatchEvents] = useState([]);
    const matchRef = useRef([]);

    const checkSubscribeToEvents = (events) => {
        const isMatchNotToday = !moment().isSame(moment(match.startEventDate), 'date');
        const minutesToStart = moment().diff(moment(match.startEventDate), 'minutes', true);
        const isMoreThan90MinutesToStart = match.matchStatus === MATCH_STATUS.PLANNED && TIME_START_SUBSCRIPTION_BEFORE + minutesToStart < 0;
        const isNoEventsBeforeStart = isMoreThan90MinutesToStart && events && events.length === 0;
        const unAcceptableMatchStatus = match.matchStatus === MATCH_STATUS.FINISHED
            || match.matchStatus === MATCH_STATUS.CANCELLED
            || match.matchStatus === MATCH_STATUS.INTERRUPTED;
        if (isMatchNotToday || unAcceptableMatchStatus || isMoreThan90MinutesToStart || isNoEventsBeforeStart) {
            return false;
        }
        return true;
    };

    const getEvents = async () => {
        const events = await getAllMatchEventsById(match.slug.current);
        events.sort((a, b) => Number(b.minute) - Number(a.minute));
        const isSubscribe = checkSubscribeToEvents(events);
        setIsSubscribeToEvents(isSubscribe);
        if (events && events.length) {
            matchRef.current = events;
            setMatchEvents(events);
        }
    };

    const getEvent = async (eventId, transitionType) => {
        getEventById(eventId).then(res => {
            const newEvent = transitionType !== TRANSITION_TYPE.disappear && res[0];
            const copyEvents = [...matchRef.current];
            switch (transitionType) {
                case TRANSITION_TYPE.appear: {
                    copyEvents.push(newEvent);
                    copyEvents.sort((a, b) => Number(b.minute) - Number(a.minute));
                    matchRef.current = [...copyEvents];
                    setMatchEvents(copyEvents);
                    break;
                }
                case TRANSITION_TYPE.update: {
                    const index = copyEvents.findIndex(oldEvent => oldEvent.id === eventId);
                    copyEvents[index] = newEvent;
                    matchRef.current = [...copyEvents];
                    setMatchEvents(copyEvents);
                    break;
                }
                case TRANSITION_TYPE.disappear: {
                    const index = copyEvents.findIndex(oldEvent => oldEvent.id === eventId);
                    copyEvents.splice(index, 1);
                    matchRef.current = [...copyEvents];
                    setMatchEvents(copyEvents);
                    break;
                }
                default:
                    break;
            }
        });
    };

    const subscribeToEvents = () => {
        const listenerQuery = `*[_type == "matchEvent" && match._ref == "${match._id}" && !(_id in path("drafts.**"))]`;
        return client.listen(listenerQuery, {}, { includeResult: false })
            .subscribe((update) => {
                if (update.documentId) {
                    const t = setTimeout(() => {
                        getEvent(update.documentId, update.transition);
                        clearTimeout(t);
                    }, 1000);
                }
            });
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        let subscription;
        if (isSubscribeToEvents) {
            subscription = subscribeToEvents();
        }
        return () => subscription && subscription.unsubscribe();
    }, [isSubscribeToEvents]);

    const handleLoadMoreImages = () => {
        setNextEvents(nextEvents + EVENTS_PER_PAGE);
    };

    const renderEventIcon = (eventType, isHammarby, minute) => {
        if (eventType === 'goal' && isHammarby) {
            return (
                <div className={`${style.matchLive__commentIcon} ${style.matchLive__goalLocal}`}>
                    <span className="text-md text-semibold">
                        {minute <= 90 ? `${minute}'` : `90+${minute - 90}'`}
                    </span>
                    <i className={`ico-ball ${style.matchLive__ballIcon}`} aria-hidden="true" />
                </div>
            );
        }

        if (eventType === 'goal' && !isHammarby) {
            return (
                <div className={`${style.matchLive__commentIcon} ${style.matchLive__goalExternal}`}>
                    <span className="text-md text-semibold">
                        {minute <= 90 ? `${minute}'` : `90+${minute - 90}'`}
                    </span>
                    <i className={`ico-ball ${style.matchLive__ballIcon}`} aria-hidden="true" />
                </div>
            );
        }

        return (
            <div className={`${style.matchLive__commentIcon}`}>
                <span className="text-md text-semibold">
                    {minute <= 90 ? `${minute}'` : `90+${minute - 90}'`}
                </span>
                {eventType === 'goal' && (
                    <i className={`ico-ball ${style.matchLive__ballIcon}`} aria-hidden="true" />
                )}
                {eventType === 'switch-players' && (
                    <div className={style.matchLive__arrowsComponent}>
                        <i
                            className={`ico-arrow ${style.matchLive__arrowRight} ${style.matchLive__redArrow}`}
                            aria-hidden="true"
                        />
                        <i
                            className={`ico-arrow ${style.matchLive__arrowLeft}`}
                            aria-hidden="true"
                        />
                    </div>
                )}
                {eventType === 'yellow-card' && <div className={style.matchLive__yellowCard} />}
                {eventType === 'red-card' && <div className={style.matchLive__redCard} />}
            </div>
        );
    };

    const playVideo = (videoUrl) => {
        setVideoPlayingIUrl(`${videoUrl}?auto_play=true`);
        setVideoPlaying(true);
    };

    const stopVideo = () => {
        setVideoPlayingIUrl(null);
        setVideoPlaying(false);
    };
/*
    if (match.matchStatus === MATCH_STATUS.PLANNED || match.matchStatus === MATCH_STATUS.CANCELLED) {
        return null;
    }
*/
    return (
        <div className={style.matchLive}>
            <div className={style.matchLive__header} />
            {matchEvents.slice(0, nextEvents).map((matchEvent, index) => (
                <div className={style.matchLive__commentSection} key={index}>
                    <div className={style.matchLive__timelineItem}>
                        {renderEventIcon(
                            matchEvent.type.name,
                            matchEvent.club?.isHammarby,
                            matchEvent.minute,
                        )}
                    </div>
                    <div>
                        <div className={`text-md text-semibold ${style.matchLive__commentTitle}`}>
                            <FormattedMessage id={`match.events.event-${matchEvent?.type?.name}`} />
                        </div>
                        <div className={`text-sm ${style.matchLive__commentDescription}`}>
                            {matchEvent?.comment?.comment}
                        </div>
                        <div className={style.matchLive__imagesContainer}>
                            {matchEvent?.comment?.images &&
                                matchEvent.comment.images.slice(0, 3).map((image, imageIndex) => (
                                    <div className={`${style.matchLive__image} ${itemStyle.newsItem__img}`}
                                         key={imageIndex}>
                                        <ImageGatsby {...image}
                                                     alt={image.asset.altText || matchEvent?.comment?.title || ''} />
                                    </div>
                                ))}
                            {matchEvent.comment?.images?.length > 3 && (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                                <div
                                    className={style.matchLive__imageOverlay}
                                    onClick={() => openImageGallery(matchEvent.id)}
                                >
                                    <span className="text-lg text-medium">
                                        {`+${matchEvent.comment.images.length - 2}`}
                                    </span>
                                </div>
                            )}
                        </div>
                        {matchEvent?.comment?.video && (
                            <div>
                                <div className={itemStyle.newsItem__img}>
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                    <div className={style.matchLive__videoCover}
                                         onClick={() => playVideo(matchEvent.comment.video)} />
                                    <EmbedVideoPlayer
                                        url={matchEvent.comment.video}
                                        title={matchEvent?.comment?.title}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}

            {/* Video Modal */}
            <Modal
                className={itemStyle.newsItem__videoModal}
                show={videoPlaying && videoPlayingUrl}
                onClose={stopVideo}
            >
                <EmbedVideoPlayer url={videoPlayingUrl} title={videoPlayingUrl} />
            </Modal>

            <div className={style.matchLive__footer} />
            {nextEvents < matchEvents.length && (
                <ul className="tabs">
                    <li>
                        <button className="btn-reset" onClick={handleLoadMoreImages} type="button" aria-label={getFormattedMessage('global.loadMore', lang)}>
                            <FormattedMessage id="global.loadMore" />
                        </button>
                    </li>
                </ul>
            )}
            {/* TODO dynamize translation & field once implemented */}
            {/* <div className={style.matchLive__source}></div> */}
        </div>
    );
};

export default MatchLive;
