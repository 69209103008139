import React, { useContext, useRef, useState } from 'react';
import SlickSlider from 'react-slick';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/match/page.module.scss';
import { breakpoints } from 'data/utils';
import { MATCH_STATUS } from 'data/constants';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import NewsBlockList from 'components/news/BlockList';
import Modal from 'components/utils/Modal';

import MatchHeader from './PageParts/Header';
import MatchLive from './PageParts/Live';
// import MatchLineUpField from './PageParts/LineUpField';
// import MatchQuality from './PageParts/MatchQuality';
import MatchAnalysis from './PageParts/MatchAnalysis';
import MatchLineUpPlayers from './PageParts/LineUpPlayers';
import MatchStats from './PageParts/Statistics';
import LiveStream from './PageParts/LiveStream';
import MatchPartner from './PageParts/Partner';

const SLIDES = {
    slidesDesktop: 3,
    slidesDesktopSmall: 2,
    slidesTablet: 2,
    slidesMob: 1,
};

const MatchPage = ({ page }) => {
    const { lang } = useContext(LangContext);
    const refLive = useRef();
    const refLineUp = useRef();
    const refAnalysis = useRef();

    const [activeTab, setActiveTab] = useState(
        page.match?.matchStatus !== MATCH_STATUS.PLANNED ? refLive : refLineUp,
    );

    const [isMobile, setIsMobile] = useState(
        typeof window !== 'undefined' && window.innerWidth < 1100,
    );
    const [openModal, setOpenModal] = useState(false);

    const divStyle = {
        width: '100%',
        maxWidth: '800px',
        margin: '20px auto 0 auto',
    };

    React.useEffect(() => {
        const setMobile = () => {
            if (typeof window !== 'undefined') {
                setIsMobile(window.innerWidth < 1100);
            }
        };

        window.addEventListener('resize', setMobile);

        return () => {
            window.removeEventListener('resize', setMobile);
        };
    }, []);

    const openImageGallery = (id) => {
        setOpenModal(id);
    };

    const sliderSettings = {
        dots: true,
        arrows: false,
        infinite: false,
        mobileFirst: true,
        adaptiveHeight: true,
        slidesToShow: SLIDES.slidesDesktop,
        slidesToScroll: SLIDES.slidesDesktop,
        responsive: [
            {
                breakpoint: breakpoints.small,
                settings: 'unslick',
            },
            {
                breakpoint: breakpoints.medium,
                settings: {
                    slidesToShow: SLIDES.slidesTablet,
                    slidesToScroll: SLIDES.slidesTablet,
                },
            },
            {
                breakpoint: breakpoints.large,
                settings: {
                    slidesToShow: SLIDES.slidesDesktopSmall,
                    slidesToScroll: SLIDES.slidesDesktopSmall,
                },
            },
        ],
    };
    const modalImages = page.matchEvents
        .filter((event) => event.id === openModal)
        .map((item) => item.comment.images);

    return (
        <div className={style.match}>
            <Modal className="modal-open" show={openModal} onClose={() => setOpenModal(false)}>
                <div className={style.match__imageGalleryWrapper}>
                    <div className={style.match__imageGallery}>
                        <SlickSlider {...sliderSettings}>
                            {modalImages[0] &&
                                modalImages[0].map((image) => (
                                    <div className={style.match__image}>
                                        <ImageGatsby {...image} />
                                    </div>
                                ))}
                        </SlickSlider>
                    </div>
                </div>
            </Modal>
            <MatchHeader match={page.match} matchEvents={page.matchEvents} />
            <div className={style.match__hideDesktop}>
                <div className="bg">
                    <MatchStats match={page.match} />
                </div>
            </div>
            <div className={`${style.match__content}`}>
                <div className={style.match__main}>
                    { /*
                    {page.match.wyscoutId &&
                        <MatchQuality wyscoutId={page.match.wyscoutId} />
                    }
                    */ }
                    {page.match.liveStream && (
                        <div className="wrapper" style={divStyle}>
                            <LiveStream match={page.match} />
                        </div>
                    )}
                    <ul className="tabs tabs--centered">
                        <li key="itemLive">
                            <button
                                className={activeTab === refLive ? 'active' : ''}
                                onClick={() => setActiveTab(refLive)}
                                type="button"
                                aria-label={getFormattedMessage('match.live', lang)}
                            >
                                <FormattedMessage id="match.live" />
                            </button>
                        </li>
                        <li key="itemLineup">
                            <button
                                className={activeTab === refLineUp ? 'active' : ''}
                                onClick={() => setActiveTab(refLineUp)}
                                type="button"
                                aria-label={getFormattedMessage('match.lineUp', lang)}
                            >
                                <FormattedMessage id="match.lineUp" />
                            </button>
                        </li>
                        {page.match.wyscoutId &&
                            <li key="itemAnalysis">
                                <button
                                    className={activeTab === refAnalysis ? 'active' : ''}
                                    onClick={() => setActiveTab(refAnalysis)}
                                    type="button"
                                    aria-label={getFormattedMessage('match.analysis', lang)}
                                >
                                    <FormattedMessage id="match.analysis" />
                                </button>
                            </li>
                        }
                    </ul>
                    {activeTab === refLive ? (
                        <div ref={refLive} className={style.match__matchLiveContainer}>
                            <MatchLive
                                isModalOpen={openModal}
                                match={page}
                                openImageGallery={openImageGallery}
                            />
                        </div>
                    ) : null}
                    {activeTab === refLineUp ? (
                        <div ref={refLineUp}>
                            {/* <MatchLineUpField match={page} /> */}
                            <MatchLineUpPlayers match={page} />
                        </div>
                    ) : null}
                    {activeTab === refAnalysis && page.match.wyscoutId ? (
                        <div ref={refAnalysis}>
                            <MatchAnalysis match={page.match} />
                        </div>
                    ) : null}
                </div>
                <div className={`${style.match__aside} bg`}>
                    <div className={style.match__hideMobile}>
                        <MatchStats match={page.match} />
                    </div>
                    <MatchPartner match={page.match} />
                </div>
            </div>
            <NewsBlockList title={<FormattedMessage id="match.matchNews" />} items={page.news} />
            {isMobile && (
                <div>
                    &nbsp;
                </div>
            )}
        </div>
    );
};

export default MatchPage;
